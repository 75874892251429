<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{$t("Détails de votre profil utilisateur")}}</h3>
      </div>
    </div>
    <div id="kt_account_profile_details" class="collapse show">
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges1()"
        :validation-schema="profileDetailsValidator"
      >
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >{{$t("Prénom Nom")}}</label
            >
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="con_prenom"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    :placeholder="$t('Prénom')"
                    v-model="profileDetails.con_prenom"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="con_prenom" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="con_nom"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('Nom de famille')"
                    v-model="profileDetails.con_nom"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="con_nom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >{{$t("Votre fonction")}}</label
            >
            <div class="col-lg-8 fv-row">
              <Field
                type="text"
                name="con_role"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Fonction')"
                v-model="profileDetails.con_role"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="con_role" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              <span>{{$t("Téléphone Fixe")}}</span>
            </label>
            <div class="col-lg-8 fv-row">
              <Field
                type="tel"
                name="con_tel1"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Téléphone')"
                v-model="profileDetails.con_tel1"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="con_tel1" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >{{$t("Téléphone portable")}}</label
            >
            <div class="col-lg-8 fv-row">
              <Field
                type="text"
                name="con_tel2"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Téléphone portable')"
                v-model="profileDetails.con_tel2"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="con_tel2" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >{{$t("Votre Cisco CCO ID")}}</label
            >
            <div class="col-lg-8 fv-row">
              <Field
                type="text"
                name="con_ccoid"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Cisco CCO ID')"
                v-model="profileDetails.con_ccoid"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="con_ccoid" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >{{$t("Votre Clé API MERAKI")}}</label
            >
            <div class="col-lg-6 fv-row">
              <Field
                type="password"
                name="con_meraki_key"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Clé API MERAKI')"
                v-model="profileDetails.con_meraki_key"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="con_meraki_key" />
                </div>
              </div>
            </div>
            <div class="col-lg-2 fv-row">
              <div class="btn btn-light text-dark w-100" @click="testAPiMeraki()">
                <span class="indicator-label"> {{$t("Tester la clé")}} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton1"
            class="btn btn-primary"
          >
            <span class="indicator-label"> {{$t("Enregistrer les changements")}} </span>
            <span class="indicator-progress">
              {{$t("Veuillez patientez...")}}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </Form>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">{{$t("Profil de connexion")}}</h3>
      </div>
    </div>
    <div id="kt_account_signin_method" class="collapse show">
      <div class="card-body border-top p-9">
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_email" :class="{ 'd-none': emailFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">{{$t("Votre adresse Email")}}</div>
            <div class="fs-6 fw-bold text-gray-600">
              {{ profileDetails.con_mail }}
            </div>
          </div>
          <div
            id="kt_signin_email_edit"
            :class="{ 'd-none': !emailFormDisplay }"
            class="flex-row-fluid"
          >
            <Form
              id="kt_signin_change_email"
              class="form"
              novalidate="novalidate"
              @submit="updateEmail()"
              :validation-schema="changeEmail"
            >
              <div class="row mb-6">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="fv-row mb-0">
                    <label
                      for="emailaddress"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Veuillez saisir votre adresse Email")}}</label
                    >
                    <Field
                      type="email"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      id="emailaddress"
                      :placeholder="$t('Email Address')"
                      name="emailaddress"
                      v-model="profileDetails.con_newmail"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="emailaddress" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmemailpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Confirmer votre mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="confirmemailpassword"
                      v-model="profileDetails.con_password"
                      id="confirmemailpassword"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmemailpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_signin_submit"
                  type="submit"
                  ref="updateEmailButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label"> {{$t("Modifier votre Email")}} </span>
                  <span class="indicator-progress">
                    {{$t("Veuillez patienter...")}}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <button
                  id="kt_signin_cancel"
                  type="button"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                  @click="emailFormDisplay = !emailFormDisplay"
                >
                  {{$t("Annuler")}}
                </button>
              </div>
            </Form>
          </div>
          <div
            id="kt_signin_email_button"
            :class="{ 'd-none': emailFormDisplay }"
            class="ms-auto"
            v-if="state.role=='Administrateur'" 
          >
            <button
              class="btn btn-light fw-boldest px-6"
              @click="emailFormDisplay = !emailFormDisplay"
            >
              {{$t("Changer votre adresse mail")}}
            </button>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div
            id="kt_signin_password"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <div class="fs-4 fw-boldest mb-1">{{$t("Mot de passe")}}</div>
            <div class="fs-6 fw-bold text-gray-600">************</div>
          </div>
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <div class="fs-6 fw-bold text-gray-600 mb-4">
              {{$t("Le mot de passe doit faire minimum 8 caractères.")}}
            </div>
            <Form
              id="kt_signin_change_password"
              class="form"
              novalidate="novalidate"
              @submit="updatePassword()"
              :validation-schema="changePassword"
            >
              <div class="row mb-6">
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="currentpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Mot de passe actuel")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="currentpassword"
                      id="currentpassword"
                      v-model="profileDetails.con_password"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="currentpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="newpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Nouveau mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="newpassword"
                      id="newpassword"
                      v-model="profileDetails.con_password_new"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="newpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Confirmation de votre nouveau mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="confirmpassword"
                      id="confirmpassword"
                      v-model="profileDetails.con_password_new_confirm"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_password_submit"
                  type="submit"
                  ref="updatePasswordButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label">
                    {{$t("Modifier votre mot de passe")}}
                  </span>
                  <span class="indicator-progress">
                    {{$t("Veuillez patienter...")}}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <button
                  id="kt_password_cancel"
                  type="button"
                  @click="passwordFormDisplay = !passwordFormDisplay"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                >
                  {{$t("Annuler")}}
                </button>
              </div>
            </Form>
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="passwordFormDisplay = !passwordFormDisplay"
              class="btn btn-light fw-boldest"
            >
              {{$t("Changer votre mot de passe")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import { ElNotification } from 'element-plus'
import { useStore } from "vuex";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

interface ProfileDetails {
  con_prenom: string;
  con_nom: string;
  con_role: string;
  con_tel1: string;
  con_tel2: string;
  con_ccoid: string;
  con_meraki_key: string,
  con_mail: string;
  con_newmail: string;
  con_password: string;
  con_password_new: string;
  con_password_new_confirm: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n()
    const submitButton1 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const mStore = useStore();

    const state = reactive({
      role : mStore.getters.currentUser.con_role_web,
    });

    const profileDetailsValidator = Yup.object().shape({
      con_prenom: Yup.string().nullable().required(t("Le prénom est obligatoire")),
      con_nom: Yup.string().nullable().required(t("Le nom est obligatoire")),
      con_role: Yup.string().nullable().required(t("La fonction est obligatoire")),
      con_tel1: Yup.string().nullable().label(t("Télephone Fixe")),
      con_tel2: Yup.string().nullable().label(t("Télephone Portable")),
      con_ccoid: Yup.string().nullable().label(t("CCO ID")),
      con_meraki_key: Yup.string().nullable().label(t("Clé MERAKI")),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label(t("Email")),
      confirmemailpassword: Yup.string().required().label(t("Password")),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string()
        .required(t("Mot de passe actuel obligatoire"))
        .label(t("Current password")),
      newpassword: Yup.string()
        .min(8)
        .required(t("Mot de passe actuel obligatoire"))
        .label(t("Password")),
      confirmpassword: Yup.string()
        .min(8)
        .required(t("Confirmation du nouveau Mot de passe obligatoire"))
        .oneOf(
          [Yup.ref("newpassword"), null],
          t("Les nouveaux mots des passes doivent être identiques")
        )
        .label(t("Password Confirmation")),
    });

    const profileDetails = ref<ProfileDetails>({
      con_prenom: "",
      con_nom: "",
      con_role: "",
      con_tel1: "",
      con_tel2: "",
      con_ccoid: "",
      con_meraki_key : "",
      con_mail: "",
      con_newmail: "",
      con_password: "",
      con_password_new: "",
      con_password_new_confirm: "",
    });

    const testAPiMeraki = () => {
      mAxiosApi
          .get("testAPIMeraki/" + profileDetails.value.con_meraki_key)
          .then(({ data }) => {
            if(data.results.length > 0) {
              console.log(data);
              ElNotification({
                title: 'Succès',
                message: 'Votre clé semble valide',
                type: 'success',
              })
            }else {
              ElNotification({
                title: 'Erreur',
                message: 'Votre clé ne semble pas valide',
                type: 'error',
              }) 
            }
          })
          .catch((err) => {
            console.error(err);
          });
    }

    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateProfil", profileDetails)
          .then(({ data }) => {
            // console.log(data["results"]);
            submitButton1.value?.removeAttribute("data-kt-indicator");

            store.commit(Mutations.SET_USER, data["results"]);

            Swal.fire({
              text: t("Changement de vos informations personnelles correctement effectué !"),
              icon: "success",
              confirmButtonText: t("Ok"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateMail", profileDetails)
          .then(({ data }) => {
            // console.log(data);
            updateEmailButton.value?.removeAttribute("data-kt-indicator");

            if (data["results"] !== 0) {
              store.commit(Mutations.SET_USER, data["results"]);
              emailFormDisplay.value = false;
              profileDetails.value.con_mail = profileDetails.value.con_newmail;
              Swal.fire({
                text: t("Changement de votre Email correctement effectué !"),
                icon: "success",
                confirmButtonText: t("Ok"),
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            } else {
              Swal.fire({
                text: t("Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp."),
                icon: "error",
                confirmButtonText: t("Ok"),
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("password", {
            username: profileDetails.value.con_mail,
            password: profileDetails.value.con_password,
            newPassword: profileDetails.value.con_password_new,
          })
          .then(({ data }) => {
            // console.log(data);
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            passwordFormDisplay.value = false;
            Swal.fire({
              text: t("Changement de votre mot de passe correctement effectué !"),
              icon: "success",
              confirmButtonText: t("Ok"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch(() => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: t("Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp."),
              icon: "error",
              confirmButtonText: t("Ok"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Paramètres du compte", [""]);
      let meAndMyself = await mAxiosApi.prototype.getAxios("/me");
      // console.log(meAndMyself);

      profileDetails.value.con_prenom = meAndMyself.con_prenom;
      profileDetails.value.con_nom = meAndMyself.con_nom;
      profileDetails.value.con_role = meAndMyself.con_role;
      profileDetails.value.con_tel1 = meAndMyself.con_tel1;
      profileDetails.value.con_tel2 = meAndMyself.con_tel2;
      profileDetails.value.con_ccoid = meAndMyself.con_ccoid;
      profileDetails.value.con_meraki_key = meAndMyself.con_meraki_key;
      profileDetails.value.con_mail = meAndMyself.con_mail;
      profileDetails.value.con_newmail = meAndMyself.con_mail;
      profileDetails.value.con_password = "";
      profileDetails.value.con_password_new = "";
      profileDetails.value.con_password_new_confirm = "";
    });

    return {
      submitButton1,
      saveChanges1,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      testAPiMeraki,
      state
    };
  },
});
</script>
